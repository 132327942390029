import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersComunicateComponentsService {
 /******************************************/
      /* para recargar la lista de creditos */
      private refreshCustomersListSubject = new BehaviorSubject<void>(undefined);

      refreshCustomersList$ = this.refreshCustomersListSubject.asObservable();

      refreshCustomersList() {
            this.refreshCustomersListSubject.next();
      }
      /******************************************/
}
